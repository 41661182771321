import axios from 'axios';

export default class MedicineTopics {


        getTopics(){

           return axios.get('/api/medicine-topics/getTopics')
        }


        registerOnTopic(topicID){

            return axios.post('/api/medicine-topics/registerOnTopic',{topicID})
        }

        getRegisteredTopics(){

            return axios.get('/api/medicine-topics/getRegisteredTopic')

        }


        deleteRegisteredTopic(registeredTopicID){

            return axios.post('/api/medicine-topics/deleteRegisteredTopic',{registeredTopicID})


        }


}