<template>
    <div class="container-fluid">
      <div class="row">
  
        <sidebar></sidebar>
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
          <div class="row">
            <div class="col-12 mb-5">
              <app-header></app-header> 
            </div>
          </div>

            <div v-if="registeredTopics.length>0">
                <p class="text-center">არჩეული თემა</p>
                <div class="bg-white p-4">
                         
                    <h4   class="text-center cursor-pointer" v-if="topics.length>0" v-for="(topic,i) of registeredTopics" :key="i">  {{ helpers.getLocalizedField(topic, 'topic') }} - {{ helpers.getLocalizedField(topic, 'fname') }}  {{ helpers.getLocalizedField(topic, 'lname') }} 
                    
                        <span @click="deleteRegisteredTopic(topic.medicineTopicRegID)" v-if="topic.canDelete==1" class="text-danger">X</span>
                    
                    </h4>
                     
                </div>


            </div>
            <div v-else class="bg-white p-4">
            <h4 @click="chooseTopic(topic.topicID)" class="text-center cursor-pointer" v-if="topics.length>0" v-for="(topic,i) of topics" :key="i">  {{ helpers.getLocalizedField(topic, 'topic') }} - {{ helpers.getLocalizedField(topic, 'fname') }}  {{ helpers.getLocalizedField(topic, 'lname') }} </h4>
            
        </div>

        </main>

</div>
</div>

</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header'; 

import SkList from '../components/skeletons/sk-list';
import MedicineTopicResources from  '../api/medicineTopicsResources';
 

const medicineTopicsResources = new MedicineTopicResources()

export default{

    data(){

        return{

                topics:[],
                registeredTopics:[]



        }


    },

    mounted(){

         this.getTopics()

            this.getRegisteredTopic()

    },

    components: { SkList,  sidebar, appHeader },

    methods:{

        getTopics(){

            medicineTopicsResources.getTopics().then(response=>{


                    this.topics=response.data

                    })

        },


        deleteRegisteredTopic(registeredTopicID){

            if(confirm("ნამდვილად გსურთ თემის წაშლა?")){

            medicineTopicsResources.deleteRegisteredTopic(registeredTopicID).then(response=>{

                        if(response.data.status=='OK'){
                           
                            location.reload()

                        }else {

                            this.helpers.notifyErrorMessage('თემა არ წაიშალა. სცადეთ თავიდან');

                        }


            })

        }


        },


        chooseTopic(topicID){

            if(confirm('ნამდვულად გსურთ ამ თემის არჩევა?')){
            medicineTopicsResources.registerOnTopic(topicID).then(response=>{

                if(response.data.status=='OK'){
           
                    location.reload()

                }
                if(response.data.status=='SomethingWentWrong'){
                    this.helpers.notifyErrorMessage('სცადეთ თავიდან');

                }
                if(response.data.status=='ThereIsNoFreeSpaces'){

                    this.helpers.notifyErrorMessage('არ არის თავისუფალი ადგილები');

                }



            })

        }    

        },

        getRegisteredTopic(){

            medicineTopicsResources.getRegisteredTopics().then(response=>{


                this.registeredTopics=response.data

                

            })


        }

    }


}


</script>
<style>
.cursor-pointer{
    cursor: pointer;
}

</style>