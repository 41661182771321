<template>
<div class="container-fluid">
    <div class="row">
        <sidebar></sidebar>
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
            <div class="row">
          <div class="col-12 mb-5">
            <app-header></app-header>
            
          </div>
        </div>


        <section class="mx-auto w-50">
            <h2  >
                {{$t('registerProblems.title')}}
        </h2>
 
        <form action="" enctype="multipart/form-data">
            <table>
                <tr>
                    <td>{{$t('registerProblems.type')}}  <span style="color: red; font-size: 20px;">*</span></td>
                    <td>


                        <multiselect
                    id="problem_type"
                    v-model="problem_type"
                    :options="list"
                    :track-by="locale==='ka'?'value':'valueeng'"
                    :label="locale==='ka'?'title':'titleeng'"
                     
                 />
                     
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><br><br><br></td>
                </tr>
                <tr>
                    <td>{{$t('registerProblems.place')}}</td>
                    <td>
                        <input type="text" v-model="place" :placeholder="$t('registerProblems.place')" style="width: 340px" class="form-control">
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><br><br><br></td>
                </tr>
                <tr>
                    <td>{{$t('registerProblems.claim')}} <span style="color: red; font-size: 20px;">*</span></td>
                    <td>
                        <textarea v-model="problem" id="" class="form-control" style="width: 350px; height: 150px;"></textarea>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><br><br><br></td>
                </tr>
                <tr>
                    <td>{{$t('registerProblems.file')}}<br><span style="color: red;">(jpg,jpeg,png,rar,zip,mp4)</span></td>
                    <td>
                        <input type="file" @change="handleFile" class="form-control">
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><br><br><br></td>
                </tr>
                <tr>
                    <td colspan="2" align="center">
                        <button type="button" class="btn btn-danger"  @click="saveProblem()">{{$t('registerProblems.save')}}</button>
                    </td>
                </tr>
            </table>
        </form>
    
    </section>
        </main>
    </div>
 
</div>

</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import Multiselect from 'vue-multiselect';
import SkList from '../components/skeletons/sk-list';
import { mapGetters } from 'vuex';
export default{

    components: { SkList,  sidebar, appHeader, pageHeader,Multiselect },

    data(){
        return{
            problem_type:0,
            place:null,
            problem:null,
            files:null,
            formData:new FormData(),
            list:[
                {
                    value:'ინფრასტრუქტურის ფიზიკური მდგომარეობა /დასუფთავება',
                    title:'ინფრასტრუქტურის ფიზიკური მდგომარეობა /დასუფთავება',
                    valueeng:'condition of the infrastructure / cleaning',
                    titleeng:'condition of the infrastructure / cleaning'

                },
                {
                    value:'ტექნიკური საკითხები',
                    title:'ტექნიკური საკითხები',
                    titleeng:'Technical issue',
                    valueeng:'Technical issue',

                },
                {
                    value:'მომსახურება',
                    title:'მომსახურება',
                    titleeng:'Service',
                    valueeng:'Service',

                },
                {
                    value:'სხვა',
                    title:'სხვა',
                    titleeng:'Other',
                    valueeng:'Other',

                }


            ]






        }


    },
    computed:{
    ...mapGetters({
      locale: 'language/locale'
    })

    
},

     


    methods:{

        handleFile(e){

            this.files=e.target.files[0]
                

        },
        saveProblem(){

                if(this.problem_type==0 || !this.problem){

                    alert(this.$t('registerProblems.alert'));
                    return
                }
           

            this.formData.append('problem_type',this.problem_type.value)
            this.formData.append('place',this.place)
            this.formData.append('problem',this.problem)
            this.formData.append('files',this.files)


            this.$store.dispatch('user/problem',this.formData).then(response=>{

                this.helpers.notifySuccessMessage(this.$t('registerProblems.success'));
            })

            
        

        }

    }


}

</script>
<style scoped>

@import "~vue-multiselect/dist/vue-multiselect.min.css";

</style>