import axios from 'axios';

export default class BookResource {
  books(page, perPage) {
    return axios.get('/api/books?page=' + page + '&per_page=' + perPage);
  }

  book(bookId) {
    return axios.get('/api/books/' + bookId);
  }

  onlineBooks() {
    return axios.get('/api/online-books');
  }


  downloadFile(file){
    const url = '/api/online-books/downloadFile/'+file;

    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });

  }
}
